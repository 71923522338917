import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import frLocale from "date-fns/locale/fr";
import nbLocale from "date-fns/locale/nb";
import nnLocale from "date-fns/locale/nn";
import svLocale from "date-fns/locale/sv";
import ukLocale from "date-fns/locale/uk";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import nb from "./nb.json";
import nn from "./nn.json";
import sv from "./sv.json";
import uk from "./uk.json";

const messages = {
  en,
  sv,
  nb,
  nn,
  de,
  fr,
  es,
  uk,
};

export const localeMap = (locale: string) => {
  switch (locale) {
    case "nb":
      return { locale: nbLocale, ampm: false, format: "dd.MM.yyyy" };
    case "sv":
      return { locale: svLocale, ampm: false, format: "yyyy-MM-dd" };
    case "nn":
      return { locale: nnLocale, ampm: false, format: "dd.MM.yyyy" };
    case "de":
      return { locale: deLocale, ampm: false, format: "yyyy-MM-dd" };
    case "fr":
      return { locale: frLocale, ampm: false, format: "dd/MM/yyyy" };
    case "es":
      return { locale: esLocale, ampm: false, format: "dd/MM/yyyy" };
    case "uk":
      return { locale: ukLocale, ampm: false, format: "dd/MM/yyyy" };
    default:
      return { locale: enLocale, ampm: false, format: "dd/MM/yyyy" };
  }
};

export default messages;
